import BlogCard from "src/components/cards/BlogCard"
import { Grid } from "@mui/material"

export default function MainSection({ blogs }) {
    return (
        <Grid container spacing={2}>
            {
                blogs.map((blog, index) => (
                    <Grid item sm={4}>
                        <BlogCard blog={blog} key={`blog-${index}`} indexNumber={index} />
                    </Grid>
                ))
            }
        </Grid>
    )
}