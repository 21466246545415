import Page from "src/components/Page";
import MainSection from "./MainSection";
import { blogs } from "src/utils/constants";
import { Typography, Stack, TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

export default function BlogPage() {
    return (
        <Page title="Omodore Blog Page">
            <Stack container sx={{ px: { xs: 1, sm: 10 }, justifyContent: 'center' }}>
                <Stack sx={{ alignItems: 'center', gap: '20px', mb: 5 }}>
                    <Typography sx={{ fontSize: '32px', fontWeight: '700', color: '#0053A7', lineHeight: '38.73px' }}>Blog</Typography>
                    <Typography sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        Exploring the Future of AI Agents: Insights, Innovations, and Applications
                    </Typography>
                    <TextField
                        variant="outlined"
                        placeholder="Search..."
                        sx={{
                            width: { xs: '100%', sm: '420px' },
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                            }
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <MainSection blogs={blogs} />
            </Stack>
        </Page>
    )
}