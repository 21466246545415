import { Grid, Typography, Stack } from "@mui/material";
import Page from "src/components/Page";
import { useParams } from "react-router";
import BlogCard from "src/components/cards/BlogCard";
import { blogs } from "src/utils/constants";

export default function BlogDetailPage() {
    const { id } = useParams();

    return (
        <Page title="Omodore Blog Detail Page">
            <Grid container sx={{ px: { xs: 1, sm: 10 } }}>
                <Grid item sm={12}>
                    <BlogCard blog={blogs[id]} isActive={true} indexNumber={id} />
                    <Stack sx={{ mt: 5 }}>
                        <Typography sx={{ color: "rgba(0, 83, 167, 1)", fontWeight: '700', fontSize: '20px', lineHeight: '38.73px' }}>
                            More Articles
                        </Typography>
                        <Grid container spacing={2}>
                            {
                                blogs[id]?.related ? blogs[id].related.map((relatedBlog, index) => {
                                    return (
                                        <Grid item sm={4}>
                                            <BlogCard blog={blogs[relatedBlog]} key={`blog-${index}`} indexNumber={index} isActive={false} />
                                        </Grid>
                                    )
                                }) : ''
                            }
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Page>
    )
}