import { useState } from "react";
import { IconButton, Stack, Typography, Box } from "@mui/material";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Image from "src/components/Image";
import { useNavigate } from "react-router";
import { blogs } from "src/utils/constants";

export default function BlogCard({ blog, indexNumber, isActive }) {
    const [bookmarks, setBookmarks] = useState([]);
    const navigate = useNavigate();

    const handleBookmarkToggle = () => {
        setBookmarks((prevBookmarks) => {
            if (prevBookmarks.includes(blog.id)) {
                return prevBookmarks.filter(id => id !== blog.id);
            } else {
                return [...prevBookmarks, blog.id];
            }
        });
    };

    const isBookmarked = bookmarks.includes(blog.id);

    const handleBlog = () => {
        navigate(`/blog/${indexNumber}`);
    }

    return (
        <Stack sx={{ padding: 3, boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.3)', borderRadius: '8px', maxWidth: isActive ? '1000px' : 'auto', mx: 'auto' }}>
            <Box
                component="img"
                src={blog?.imageUrl}
                alt="Blog"
                sx={{
                    height: '240px',
                    width: '100%',
                    objectFit: 'cover',
                }}
            />
            <Stack
                spacing={3}
                sx={{
                    zIndex: '1001',
                    background: '#fff',
                    mx: 'auto',
                    mt: '20px',
                    borderRadius: '4px'
                }}>
                <Stack spacing={2} direction={!isActive ? 'column' : 'row'}>
                    {isActive &&
                        <Stack sx={{ ml: 'auto', mr: 0, mt: '-10px !important' }} >
                            <IconButton
                                sx={{
                                    width: "48px",
                                    height: "48px",
                                }}
                                onClick={handleBookmarkToggle} // Add onClick Event here
                            >
                                {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                            </IconButton>
                            <IconButton
                                sx={{
                                    width: "48px",
                                    height: "48px",
                                }}
                            // onClick={() => setIsActive(!isActive)}
                            >
                                <Image src="/assets/icons/send_dark.png" alt="send icon" />
                            </IconButton>
                        </Stack>
                    }
                    <Stack spacing={3} sx={{ width: '100%' }}>
                        <Stack sx={{ fontSize: '14px', alignItems: 'center' }}>
                            {
                                isActive ?
                                    <Typography
                                        sx={{ ml: '0', mr: 'auto', cursor: 'pointer', color: '#3EB54B', fontSize: '12px' }}
                                        onClick={e => navigate('/blog')}
                                    >
                                        {'< '}Back to blog
                                    </Typography> :
                                    <Box sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <Typography sx={{ color: '#0053A7' }}>{blog?.topic}</Typography>
                                        <Typography sx={{ color: '#0053A7', display: 'flex', mr: 0, ml: 'auto' }}>{blog?.createdAt}</Typography>
                                    </Box>
                            }
                        </Stack>

                        <Typography
                            sx={{ fontSize: '20px', fontWeight: '600', lineHeight: '24.2px', cursor: 'pointer', height: '50px', color: isActive ? 'rgba(0, 83, 167, 1)' : 'black' }}
                            onClick={handleBlog}>
                            {blog?.title}
                        </Typography>
                    </Stack>
                </Stack>
                {
                    !isActive ?
                        <Typography sx={{ fontSize: '12px', height: '100px', overflow: 'hidden' }}>
                            {blog?.content}
                        </Typography> :
                        <BlogTemplate blog={blog} />
                }

                {
                    !isActive ?
                        <Stack direction='row'>
                            {/* <Stack>
                                <Typography sx={{ fontWeight: '600' }}>{blog?.visitors}</Typography> People saw
                            </Stack> */}
                            <Stack direction='row' sx={{ ml: 'auto', mr: 0 }}>
                                <IconButton
                                    sx={{
                                        width: "48px",
                                        height: "48px",
                                    }}
                                // onClick={() => setIsActive(!isActive)}
                                >
                                    <Image src="/assets/icons/send_dark.png" alt="send icon" />
                                </IconButton>
                                <IconButton
                                    sx={{
                                        width: "48px",
                                        height: "48px",
                                    }}
                                    onClick={handleBookmarkToggle}
                                >
                                    {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                                </IconButton>
                            </Stack>
                        </Stack> : ''
                }

            </Stack>
        </Stack>
    )
}

export const BlogTemplate = ({ blog }) => {
    return (
        <Stack spacing={2} sx={{ fontSize: '12px' }}>
            <Typography sx={{ fontSize: '12px' }}>
                {blog?.header}
            </Typography>
            {
                blog?.details.map((detail, index) => (
                    <Stack key={`detail-${index}`} spacing={2}>
                        <Stack>
                            {detail?.topic ?
                                <Typography sx={{ fontWeight: '600', fontSize: '14px', mb: '10px' }}>
                                    {detail?.topic}
                                </Typography> : ''
                            }
                            {detail?.desc}
                        </Stack>
                        {
                            detail?.features ? <>
                                {/* <Typography sx={{ fontSize: '12px' }}>Key features:</Typography> */}
                                <Stack>
                                    {
                                        detail?.features?.map((feature, index1) => (
                                            <Typography key={`feature-${index1}`} sx={{ fontSize: '12px' }}>
                                                &bull; {feature}
                                            </Typography>
                                        ))
                                    }
                                </Stack>
                            </> : ""
                        }
                        {
                            detail?.subTopics ?
                                <>
                                    {
                                        detail?.subTopics.map((subTopic, index1) => {
                                            return (
                                                <Stack key={`sub-topic-${index1}`}>
                                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                                                        {subTopic?.title}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: '12px', ml: '10px' }}>
                                                        {subTopic?.content}
                                                    </Typography>
                                                </Stack>
                                            )
                                        })
                                    }
                                </> : ''
                        }
                    </Stack>
                ))
            }
            <Typography sx={{ fontSize: '12px' }}>
                {blog?.footer}
            </Typography>
        </Stack>
    )
}
