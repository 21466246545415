// utils
import createAvatar from '../utils/createAvatar'
//
import Avatar from './Avatar'

// ----------------------------------------------------------------------

export default function MyAvatar({ user, ...other }) {
  return (
    <Avatar src={user?.avatar} alt={user?.name} color={user?.avatar ? 'default' : createAvatar(user?.name).color} {...other}>
      {createAvatar(user?.name).name}
    </Avatar>
  )
}
